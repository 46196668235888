import { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Input,
  TextField,
  Typography,
  Modal,
  InputAdornment,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditAdvertisementByIDMutation,
  useLazyGetAdvertisementQuery,
  usePostAdPaymentMutation,
  usePostAddAdvertisementMutation,
} from "../../services/main";
import {
  STORAGE_KEYS,
  setToStorage,
  showError,
  showToast,
} from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { AdvertisementResponse } from "../../types/General";
import CloseIcon from "@mui/icons-material/Close";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
const AddAdvertisement = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [uploads, setUploads] = useState<any>([]);
  const [isImage, setIsImage] = useState(false);
  const translation = useTranslation() as any;
  const [image, setImage] = useState<string | undefined>("");
  const [openAddCard, setOpenAddCard] = useState<boolean>(false);
  const [advertisement, setAdvertisement] = useState<AdvertisementResponse[]>(
    []
  );
  const [stripe, setStripe] = useState<string>("");
  const style = generateResponsiveStyle();
  const [getAdvertisementById, { isLoading }] = useLazyGetAdvertisementQuery();

  const [AddAdvertisement] = usePostAddAdvertisementMutation();
  const [adPayment] = usePostAdPaymentMutation();
  const [editAdvertisementById] = useEditAdvertisementByIDMutation();

  const advertisementById = async (id: string) => {
    try {
      const response: any = await getAdvertisementById({
        id: id,
      });
      console.log("get", response?.data);
      if (response?.data?.statusCode === 200) {
        setAdvertisement(response?.data?.data.advertisements || []);
        if (
          response?.data?.data?.advertisements[0]?.uploads[0]?.type === "IMAGE"
        ) {
          setIsImage(true);
          setImage(
            response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
          );
          setUploads([
            {
              link: response?.data?.data?.advertisements[0]?.uploads[0]?.link,
              type: "IMAGE",
              height: 150,
              width: 200,
              thumbnail:
                response?.data?.data?.advertisements[0]?.uploads[0]?.thumbnail,
            },
          ]);
        } else {
          setIsImage(false);
          setImage(
            response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
          );
        }
        // setImage(
        //   response?.data?.data?.advertisements[0]?.uploads[0]?.link || ""
        // );
      } else {
        setAdvertisement([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id) {
      advertisementById(id);
    }
  }, []);

  const generateThumbnail = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const video = document.createElement("video");
      video.muted = true; // Mute the video to avoid potential issues
      video.preload = "metadata";

      // Listen for the loadedmetadata event
      video.addEventListener("loadedmetadata", async () => {
        const duration = Math.min(video.duration, 1); // Ensure duration is at least 1 second
        video.currentTime = duration; // Set the currentTime to 1 second after the video starts

        // Wait for a short interval to ensure the video is loaded and ready
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second

        const canvas = document.createElement("canvas");
        canvas.width = 200; // Set thumbnail width
        canvas.height = 150; // Set thumbnail height
        const context = canvas.getContext("2d");
        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          resolve(canvas.toDataURL());
        } else {
          reject(new Error(translation.manage_advertisements.error));
        }
        video.remove();
        canvas.remove();
      });

      video.addEventListener("error", (error) => {
        reject(error);
      });

      video.src = URL.createObjectURL(file); // Set the video source
      document.body.appendChild(video); // Append the video element to the document to trigger metadata loading
      // Clean up: remove the video and canvas elements
    });
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    console.log(file, "file");

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          const imageData = {
            link: res?.data,
            thumbnail: res?.data,
            type: "IMAGE",
            height: 150,
            width: 200,
          };
          // setUploads((prevImageDataArray: any) => [
          //   ...prevImageDataArray,
          //   imageData,
          // ]);
          setUploads((prevImageDataArray: any) => [imageData]);

          setImage(res?.data);
          setIsImage(true);
        } else {
          showError(res?.message);
        }
      } else if (file.type.startsWith("video/")) {
        // Handle video upload
        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
          const thumbnail = await generateThumbnail(file);

          // Convert thumbnail data URL to Blob
          const thumbnailByteCharacters = atob(thumbnail.split(",")[1]);
          const thumbnailByteNumbers = new Array(
            thumbnailByteCharacters.length
          );
          for (let i = 0; i < thumbnailByteCharacters.length; i++) {
            thumbnailByteNumbers[i] = thumbnailByteCharacters.charCodeAt(i);
          }
          const thumbnailByteArray = new Uint8Array(thumbnailByteNumbers);
          const thumbnailBlob = new Blob([thumbnailByteArray], {
            type: "image/jpeg",
          });
          // Upload the video
          // const videoUploadResponse = await UploadVideo(thumbnailBlob);
          const thumbnailUploadResponse = await UploadMedia(thumbnailBlob);
          console.log(thumbnailUploadResponse, "thumbnailUploadResponse");

          // Prepare the video data
          const imageData = {
            link: res?.data,
            thumbnail: thumbnailUploadResponse?.data,
            type: "VIDEO",
            height: 150,
            width: 200,
          };
          // Add the new video data to the existing array
          // setUploads((prevImageDataArray: any) => [
          //   ...prevImageDataArray,
          //   imageData,
          // ]);
          setUploads((prevImageDataArray: any) => [imageData]);

          console.log("resdata", res?.data);
          setImage(res?.data);
          setIsImage(false);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError(translation.manage_advertisements.error2);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: advertisement[0]?.title || "",
      noOfHours: advertisement[0]?.noOfHours || "",
      HourlyCharge: advertisement[0]?.HourlyCharge || "",
      totalCost: advertisement[0]?.totalCost || "",
      webLink: advertisement[0]?.webLink || "",
    },

    validationSchema: Yup.object({
      title: Yup.string()
        .required(translation.manage_advertisements.field_req)
        .max(60, translation.manage_advertisements.warning1)
        .min(2, translation.manage_advertisements.warning2),
      noOfHours: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      HourlyCharge: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      totalCost: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      webLink: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        title: values.title,
        noOfHours: Number(values.noOfHours),
        HourlyCharge: Number(values.HourlyCharge),
        totalCost: Number(values.totalCost),
        webLink: values.webLink,
        appKey: new Date().toISOString(),
        uploads: uploads,
      };
      if (!uploads?.length) {
        showError(translation.manage_advertisements.error3);
        return;
      }

      if (!id) {
        setOpenAddCard(true);
      }

      if (id) {
        console.log(id);
        try {
          const response: any = await editAdvertisementById({
            id,
            body,
          });

          if (response?.data?.statusCode === 200) {
            showToast(translation.manage_advertisements.update_Toast);
            navigate("/manage-advertisements");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        // try {
        //   const response = await AddAdvertisement({body}).unwrap();
        //   if (response?.statusCode === 200) {
        //     // setToStorage(STORAGE_KEYS.adInfo, JSON.stringify(body));
        //     // window.open(response?.data);
        //     showToast(translation.manage_advertisements.add_Toast);
        //     formik.resetForm();
        //     navigate("/manage-advertisements");
        //   } else {
        //     showError(response?.message || "");
        //   }
        // } catch (error: any) {
        //   showError(error?.data?.message || "");
        //   console.log(error);
        // }
      }
    },
  });

  const paymentFormik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required(translation.manage_advertisements.field_req)
        .min(3, translation.Globals.min_3)
        .max(20),
      cardNumber: Yup.string()
        .required(translation.manage_advertisements.field_req)
        .label("Card number")
        .min(16, translation.Globals.min_16),
      expiryDate: Yup.string().required(
        translation.manage_advertisements.field_req
      ),
      cvv: Yup.string()
        .required(translation.manage_advertisements.field_req)
        .label("CVV")
        .min(3, translation.Globals.min_3),
    }),

    onSubmit: async (values) => {
      paymentFormik.setSubmitting(true);
      console.log("hiiiiiiiiiiiiiiiiiiiii");

      let body = {
        amount: Number(formik.values.totalCost),
        number: Number(values.cardNumber),
        exp_month: paymentFormik.values.expiryDate.slice(0, 2),
        exp_year: paymentFormik.values.expiryDate.slice(-2),
        cvc: paymentFormik.values.cvv,
        appKey: new Date().toISOString(),
      };

      console.log(body, "bodyyyyyyyyyyyyy");
      try {
        const response = await adPayment({ body }).unwrap();
        if (response?.statusCode === 200) {
          const { id } = response?.data;
          setOpenAddCard(false);
          paymentFormik.resetForm();
          setStripe(id);
          const body = {
            title: formik.values.title,
            noOfHours: Number(formik.values.noOfHours),
            HourlyCharge: Number(formik.values.HourlyCharge),
            totalCost: Number(formik.values.totalCost),
            webLink: formik.values.webLink,
            appKey: new Date().toISOString(),
            uploads: uploads,
            stripeChargeId: id,
          };
          try {
            const response = await AddAdvertisement(body).unwrap();
            if (response?.statusCode === 200) {
              // setToStorage(STORAGE_KEYS.adInfo, JSON.stringify(body));
              // window.open(response?.data);
              showToast(translation.manage_advertisements.add_Toast);
              formik.resetForm();
              navigate("/manage-advertisements");
            } else {
              showError(response?.message || "");
            }
          } catch (error: any) {
            showError(error?.data?.message || "");
            console.log(error);
          }
        }
      } catch (error: any) {
        showError(error?.data?.message);
      }

      // let data = {
      //   number: paymentFormik.values.cardNumber,
      //   exp_month: paymentFormik.values.expiryDate.slice(0, 2),
      //   exp_year: paymentFormik.values.expiryDate.slice(-4),
      //   cvc: paymentFormik.values.cvv,
      // };
      // if (window?.Stripe) {
      //   // @ts-ignore
      //   window?.Stripe.card.createToken(
      //     {
      //       number: paymentFormik.values.cardNumber?.slice(0, 19),
      //       exp_month: String(paymentFormik.values.expiryDate).slice(0, 2),
      //       exp_year: String(paymentFormik.values.expiryDate).slice(-2),
      //       cvc: paymentFormik.values.cvv?.trim(),
      //       name: paymentFormik.values.cardHolderName?.trim(),
      //     },
      //     async (status: number, response: any) => {
      //       if (status === 200) {
      //         let body = {
      //           token: response?.id,
      //         };
      //         try {
      //           let encryptedBody = generateEncryptedKeyBody(
      //             body
      //           ) as CommonBody;

      //           const res = await addCard(encryptedBody).unwrap();
      //           if (res?.statusCode === 200) {
      //             if (res?.data) {
      //               successToast("Card added successfully");
      //               setOpenAddCard(false);
      //               setOpen(true);
      //               getAllCards();
      //               paymentFormik.resetForm();
      //             }
      //           }
      //         } catch (error: any) {
      //           errorToast(error?.data?.message || "");
      //         }
      //       } else {
      //         errorToast(response.error.message);
      //       }
      //     }
      //   );
      // }

      formik.setSubmitting(false);
    },
  });
  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {id
              ? translation.manage_advertisements.edit_ad
              : translation.manage_advertisements.add_ad}
          </h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisements");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.advertisement_Image}
                  </Typography>
                  {image ? (
                    <div className="upload_image_preview2">
                      {isImage ? (
                        <CardMedia component="img" image={image} alt="photo" />
                      ) : (
                        <div className="upload_image_preview2">
                          <video controls width="100%" height="100%">
                            <source src={image} type="video/mp4" />
                          </video>
                        </div>
                      )}

                      <CancelIcon
                        onClick={() => {
                          setImage("");
                        }}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: ".png, .jpg, .jpeg, .svg,.mp4",
                          }}
                          // onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          //   const files = (event.target as HTMLInputElement)
                          //     .files;
                          //   if (files && files[0].type.includes("image")) {
                          //     setImage(URL.createObjectURL(files[0]));
                          //   }
                          // }}
                          onChange={handleImageUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          {/* <img
                          src={
                            image
                              ? image
                              : "/static/images/user_placeholder.png"
                          }
                          alt=""
                        /> */}
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} />

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.advertisement_Title}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="title"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={
                      translation.manage_advertisements.advertisement_Title
                    }
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    inputProps={{ maxLength: 60 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.web_Link}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="webLink"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.web_Link}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.webLink}
                    helperText={formik.touched.webLink && formik.errors.webLink}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.hours}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="noOfHours"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.hours}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.noOfHours}
                    helperText={
                      formik.touched.noOfHours && formik.errors.noOfHours
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translation.manage_advertisements.hourly_charge}{" "}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="HourlyCharge"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={
                      translation.manage_advertisements.hourly_charge
                    }
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.HourlyCharge}
                    helperText={
                      formik.touched.HourlyCharge && formik.errors.HourlyCharge
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {" "}
                    {translation.manage_advertisements.total_cost}
                  </Typography>

                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="totalCost"
                    variant="outlined"
                    className="text_field"
                    fullWidth
                    placeholder={translation.manage_advertisements.total_cost}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.totalCost}
                    helperText={
                      formik.touched.totalCost && formik.errors.totalCost
                    }
                  />
                </Grid>
                {/* <Grid item xs={4}>
                <Typography className="custom_label">
                  Start Date
                </Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  id="startDate"
                  // value={formik.values.startDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.startDate && formik.errors.startDate
                  // }
                />
              </Grid> */}

                {/* <Grid item xs={4}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  className="text_field"
                  hiddenLabel
                  type={"date"}
                  name="endDate"
                  variant="outlined"
                  fullWidth
                  id="endDate"
                  // value={formik.values.endDate}
                  // onChange={formik.handleChange}
                  // helperText={
                  //   formik.touched.endDate && formik.errors.endDate
                  // }
                />
              </Grid> */}
                <br />
              </Grid>

              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  {translation.manage_advertisements.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>

      <Modal
        open={openAddCard}
        onClose={() => {
          setOpenAddCard(false);
          paymentFormik.resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpenAddCard(false)} />
            </div>
            <h2>{translation.Globals.card_details}</h2>

            <form onSubmit={paymentFormik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className="custom_label">
                    {translation.Globals.card_holder_name}
                  </Typography>
                  <TextField
                    fullWidth
                    value={paymentFormik.values.cardHolderName}
                    variant="outlined"
                    placeholder={translation.Globals.card_holder_name}
                    className="text_field"
                    name="cardHolderName"
                    inputProps={{ maxLength: 20 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardHolderName &&
                      paymentFormik.errors.cardHolderName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className="custom_label">
                    {translation.Globals.card_number}
                  </Typography>
                  <TextField
                    fullWidth
                    // variant="outlined"
                    placeholder={translation.Globals.card_number}
                    className="text_field"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <figure>
                    //         <img src="/static/images/visa.png" alt="" />
                    //       </figure>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    name="cardNumber"
                    inputProps={{ maxLength: 16 }}
                    value={paymentFormik.values.cardNumber}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardNumber &&
                      paymentFormik.errors.cardNumber
                    }
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography variant="h6" className="custom_label">
                    {translation.Globals.expiry}
                  </Typography>

                  {/* <TextField
                    className="text_field"
                    variant="outlined"
                    placeholder="MM/YY"
                    inputProps={{ maxLength: 5 }} // Max length for MM/YY format
                    value={paymentFormik.values.expiryDate}
                    name="expiryDate"
                    onChange={(event) => {
                      let inputValue = event.target.value;

                      // Remove any non-numeric characters
                      inputValue = inputValue.replace(/\D/g, "");

                      // Format as MM/YY
                      if (inputValue.length <= 2) {
                        inputValue = inputValue;
                      } else if (inputValue.length <= 4) {
                        inputValue = `${inputValue.slice(
                          0,
                          2
                        )}/${inputValue.slice(2)}`;
                      } else {
                        inputValue = `${inputValue.slice(
                          0,
                          2
                        )}/${inputValue.slice(2, 4)}`;
                      }

                      paymentFormik.handleChange({
                        target: {
                          name: "expiryDate",
                          value: inputValue,
                        },
                      });
                    }}
                    onKeyDown={(event) => {
                      const currentValue = paymentFormik.values.expiryDate;

                      if (
                        event.key === "Backspace" &&
                        currentValue.length === 3 // Handle case where backspace is pressed on slash
                      ) {
                        event.preventDefault();
                        paymentFormik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: currentValue.slice(0, 2), // Remove slash and digit before it
                          },
                        });
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.expiryDate &&
                      paymentFormik.errors.expiryDate
                    }
                  /> */}
                  <TextField
                    className="text_field"
                    variant="outlined"
                    placeholder="MM/YY"
                    inputProps={{ maxLength: 5 }} // Max length for MM/YY format
                    value={paymentFormik.values.expiryDate}
                    name="expiryDate"
                    onChange={(event) => {
                      let inputValue = event.target.value;

                      // Remove any non-numeric characters
                      inputValue = inputValue.replace(/\D/g, "");

                      let formattedValue = "";

                      // Format as MM/YY and validate month
                      if (inputValue.length <= 2) {
                        if (parseInt(inputValue, 10) > 12) {
                          formattedValue = "12"; // Cap at 12 if month exceeds 12
                        } else {
                          formattedValue = inputValue;
                        }
                      } else if (inputValue.length <= 4) {
                        const month = inputValue.slice(0, 2);
                        const year = inputValue.slice(2);

                        if (parseInt(month, 10) > 12) {
                          formattedValue = `12/${year}`;
                        } else {
                          formattedValue = `${month}/${year}`;
                        }
                      }

                      paymentFormik.handleChange({
                        target: {
                          name: "expiryDate",
                          value: formattedValue,
                        },
                      });
                    }}
                    onKeyDown={(event) => {
                      const currentValue = paymentFormik.values.expiryDate;

                      if (
                        event.key === "Backspace" &&
                        currentValue.length === 3 // Handle backspace when cursor is after slash
                      ) {
                        event.preventDefault();
                        paymentFormik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: currentValue.slice(0, 2), // Remove slash and digit before it
                          },
                        });
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.expiryDate &&
                      paymentFormik.errors.expiryDate
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" className="custom_label">
                    {translation.Globals.CVV}
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder={translation.Globals.CVV}
                    variant="outlined"
                    className="text_field"
                    name="cvv"
                    inputProps={{ maxLength: 3 }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <figure className="cvv_img">
                    //         <img src="/static/images/cvv.svg" alt="" />
                    //       </figure>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    value={paymentFormik.values.cvv}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cvv && paymentFormik.errors.cvv
                    }
                  />
                </Grid>
                {/* </Box> */}

                <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation.manage_advertisements.save}
                  </Button>
                </Box>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </MainContainer>
  );
};

export default AddAdvertisement;
