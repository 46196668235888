import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import {
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
  setToStorage,
  showError,
} from "../../constants";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { useFormik } from "formik";
import { useAppDispatch } from "../../hooks/store";
import { usePostLoginMutation } from "../../services/auth";
import { setCredentials } from "../../reducers/authSlice";
import app from "../../utils/firebaseKeys";
import { useSocialLoginMutation } from "../../services/main";
import useTranslation from "../../hooks/Translation";
import { Select } from "@material-ui/core";

// const provider=new GoogleAuthProvider()

// const auth =getAuth(app)

const Login = () => {
  const provider = new GoogleAuthProvider();

  const auth = getAuth(app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const translation = useTranslation() as any;
  const [language, setLanguage] = useState<string>("");

  const [email, setEmail] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [loginMutation, { isLoading }] = usePostLoginMutation();
  const [socialLogin] = useSocialLoginMutation();

  //login
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue(
      translation.log_in.phone || "",
      phone?.replace(country.dialCode, "")
    );
    formik.setFieldValue(
      translation.log_in.country_code,
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    // formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
      phone: "",
      countryCode: "+91",
      // countryName: "KW",
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: email
        ? Yup.string()
            .required(translation.log_in.email_req)
            .matches(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              translation.log_in.enter_valid_email
            )
        : Yup.string().notRequired(),
      password: email
        ? Yup.string()
            .label(translation.log_in.password)
            .required(translation.log_in.password_req)
            .min(6, translation.log_in.password_atleast6)
        : // .matches(
          //   /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
          //   "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character. "
          // )
          Yup.string().notRequired(),
      phone: email
        ? Yup.string().notRequired()
        : Yup.string()
            .required(translation.log_in.phone_req)
            .min(6, translation.log_in.phone_atleast6)
            .max(16, translation.log_in.phone_atleast16),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

      let body = {
        ...(email ? { email: values.email } : { phone: values.phone }),
        ...(email ? { password: values.password } : {}),
        ...(!email ? { countryCode: values.countryCode } : {}),
        deviceToken: fcmtoken || "1234",
        deviceType: "WEB",
        appKey: new Date().toISOString(),
      };

      try {
        const response = await loginMutation(body).unwrap();
        if (response?.statusCode === 200) {
          if (email) {
            dispatch(
              setCredentials({
                user: response?.data,
                token: response?.data?.token || "",
              })
            );
            setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
            // getUserDetails();
            if (formik.values.remember) {
              setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
            } else {
              removeFromStorage(STORAGE_KEYS.credentials);
            }
            if (response?.data?.isProfileComplete) {
              navigate("/dashboard", { replace: true });
            } else if (!response?.data?.isProfileComplete) {
              const state = {
                key: response?.data?.isEmailVerify
                  ? response?.data?.email
                  : response?.data?.phone,
                page: translation.log_in.login,
                type: response?.data?.isEmailVerify
                  ? translation.log_in.email
                  : translation.log_in.phone,
                ...(!response?.data?.isEmailVerify
                  ? { countryCode: response?.data?.countryCode }
                  : {}),
              };

              navigate("/profile-setup", { state: state, replace: true });
            }
          } else {
            navigate("/verify-otp", {
              state: {
                page: translation.log_in.login,
                type: translation.log_in.phone,
                key: values.phone,
                countryCode: values.countryCode,
                // countryName: values.countryName,
                replace: true,
              },
            });
          }
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue(translation.log_in.password, rememberData?.password);
      formik.setFieldValue(translation.log_in.email, rememberData?.email);
      formik.setFieldValue(translation.log_in.remember, true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const SignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const deviceId = navigator.userAgent;

      const body = {
        email: user?.email,
        socialId: user?.uid,
        image: user?.photoURL,
        socialType: "GOOGLE",
        fullName: user?.displayName,
        appKey: new Date().toISOString(),
      };
      // console.log(translation.log_in.body, body)
      const response: any = await socialLogin({
        body,
      });
      // console.log(response, "res")

      if (response?.data?.statusCode === 200) {
        console.log(response?.data?.data?.token, "token");
        setToStorage(STORAGE_KEYS.token, response?.data?.data?.token);
        dispatch(
          setCredentials({
            user: response?.data?.data,
            token: response?.data?.data?.token,
          })
        );
        console.log(response?.data?.data?.token, "token2");

        if (response?.data?.data?.isProfileComplete) {
          navigate("/dashboard", { replace: true });
        } else {
          navigate("/profile-setup", {
            state: { type: translation.log_in.email },
          });
        }
      }

      console.log(result, "socialLOgin");
    } catch (error: any) {
      console.log(error.code, "error");
    }
  };

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      console.log(language);
      const isArabic = language === "ar";
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
      setLanguage(language as string);
    }
  };
  const handleLangChange = (event: any) => {
    console.log(event.target.value);
    setToStorage(STORAGE_KEYS.language, event.target.value);
    setLanguage(event.target.value as string);
    window.location.reload();
  };
  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (!getLang) {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, []);

  return (
    <div className="authh">
      <div className="conta_iner">
        <div className="auth_parnt">
          <div className="auth_left">
            <figure
              style={{ height: "70px", width: "140px", margin: "0 auto" }}
            >
              <img
                src="/static/images/logo.png"
                alt=""
                style={{ height: "100%", width: "100%", objectFit: "contain" }}
              />
            </figure>
            <h2>{translation?.log_in?.sign_in || ""} </h2>
            <div className="google_div">
              {email ? (
                <img
                  onClick={() => setEmail(false)}
                  className="google_icon"
                  src="/static/images/call.png"
                  alt=""
                />
              ) : (
                <img
                  onClick={() => setEmail(true)}
                  className="email_fig"
                  src="/static/images/email.png"
                  alt=""
                />
              )}

              <img
                onClick={SignInWithGoogle}
                className="google_icon"
                src="/static/images/google.png"
                alt=""
              />
              {/* <img
                className="google_icon"
                src="/static/images/facebook.png"
                alt=""
              /> */}
            </div>
            <h5>
              {translation.log_in.or_use}{" "}
              {email ? translation.log_in.email : translation.log_in.phone_num}:
            </h5>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                alignItems: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <h5>{translation.select_language}:</h5>
              <Select
                className="select_div select_dv_2 "
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                displayEmpty
                onChange={handleLangChange}
              >
                <MenuItem value="" disabled>
                  Select
                </MenuItem>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="ar">عربي</MenuItem>
              </Select>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="auth_form">
                {email ? (
                  <>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.log_in.Email}
                      </Typography>

                      <TextField
                        hiddenLabel
                        placeholder={translation.log_in.Email}
                        fullWidth
                        name={translation.log_in.email}
                        type={translation.log_in.email}
                        variant="outlined"
                        className="text_field"
                        id={translation.log_in.email}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                    <FormControl sx={{ mb: 2, width: "100%" }}>
                      <Typography className="custom_label">
                        {translation.log_in.Password}
                      </Typography>
                      <TextField
                        className="text_field"
                        hiddenLabel
                        placeholder={translation.log_in.Password}
                        fullWidth
                        name={translation.log_in.password}
                        id={translation.log_in.password}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className="eye_btn" position="end">
                              <IconButton
                                aria-label={translation.log_in.toggle_pass}
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={() =>
                                  setShowPassword(!showPassword)
                                }
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box className="remember_box">
                        <FormControlLabel
                          control={<Checkbox />}
                          checked={formik.values.remember}
                          name={translation.log_in.remember}
                          onChange={formik.handleChange}
                          label={translation.log_in.remember_me}
                        />
                      </Box>
                      <Box
                        className="anchor_link"
                        onClick={() => navigate("/forgot-password")}
                      >
                        <Typography style={{ fontWeight: 600 }}>
                          {translation.log_in.forgot_pw}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation.log_in.phone_num}
                    </Typography>
                    <PhoneInput
                      enableSearch
                      value={formik.values.countryCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translation.log_in.phone_num}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevent default behavior (form submission)
                          formik.handleSubmit(); // Manually submit the form
                        }
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}

                <Box sx={{ pt: 2, mb: 3 }}>
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {translation.log_in.log_in}
                  </Button>
                </Box>
              </div>
            </form>
          </div>
          <div className="auth_ryt">
            <div className="absolute_div">
              <h3>{translation.hello_there}</h3>
              <p>{translation.message_page}</p>
              <Box sx={{ pt: 3, display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn"
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => navigate("/sign-up")}
                >
                  {translation.log_in.sign_up}
                </Button>
              </Box>
            </div>
            <figure>
              <img src="/static/images/cook1.jpg" alt="" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
